/* Popup.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Set a higher z-index value */
  }
  
  .popup-content {
    background: white;
    padding: 40px; /* Increased padding for a larger popup */
    border-radius: 12px;
    text-align: center;
    position: relative; /* Added position relative for absolute positioning of close button */
    z-index: 1001; /* Set a higher z-index value */
    width: 300px; /* Set a larger fixed width for the popup */
    height: 400px; /* Set a larger fixed height for the popup */
    overflow: hidden; /* Hide overflowing content */
  }
  
  .image-container {
    height: 60%; /* Adjusted height to fit the image within the square */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Fit the image within the container without covering the entire square */
    border-radius: 8px;
  }
  
  .popup-price {
    color: red;
    margin-top: 10px;
    font-size: 18px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px; /* Adjusted to be on the top-right corner */
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .add-to-cart-button {
    background: #4caf50; /* Green background */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  